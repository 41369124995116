import { useMemo } from 'react';
import { useObjectExtraFields } from './useObjectExtraFields';
import { ExtraFieldsSupportedObject } from './types';

export const useDefaultRecordTypeName = (
  objectApiName: ExtraFieldsSupportedObject,
): string | null => {
  const { rawObjectExtraFields } = useObjectExtraFields(objectApiName);
  const defaultRecordTypeId = useMemo(() => {
    return (
      rawObjectExtraFields?.availableRecordTypes.find((x) => x.IsDefault)
        ?.Name ?? null
    );
  }, [rawObjectExtraFields]);
  return defaultRecordTypeId;
};
