import { useEffect, useMemo } from 'react';
import {
  ColumnRenderSettings,
  ExtraFieldsSupportedObject,
  ExtraFieldsTableId,
  ExtraTableItemType,
  ExtraTableRenderSettings,
  RecordWithExtraFields,
} from './types';
import { useObjectExtraFields } from './useObjectExtraFields';
import { CellProps, Column } from 'react-table';
import { ExtraFieldTableInfoFragment } from '../../__generated__/graphql';
import { defaultSimpleRenderer } from './defaultCellRenderers';
import { defaultLookupRenderer } from './defaultCellRenderers';
import { useErrorHandler } from '../../../../hooks/useErrorHandler';
type Props<T extends RecordWithExtraFields> = {
  objectApiName: ExtraFieldsSupportedObject;
  renderSettings?: ExtraTableRenderSettings<T>;
  tableId: ExtraFieldsTableId;
};

type Result<T extends RecordWithExtraFields> = {
  columns: Column<ExtraTableItemType<T>>[];
  loading: boolean;
};

export const useExtraTableColumns = <T extends RecordWithExtraFields>({
  objectApiName,
  renderSettings,
  tableId,
}: Props<T>): Result<T> => {
  const { getTableFields, loading, error } =
    useObjectExtraFields(objectApiName);

  const addError = useErrorHandler();

  useEffect(() => {
    if (error) {
      addError(error);
    }
  }, [error, addError]);

  const columns: Column<ExtraTableItemType<T>>[] = useMemo(() => {
    // map to avoid duplicate keys
    const columnsFromSalesforceMap = getTableFields(tableId).reduce(
      (acc, field) => {
        const columnProps = renderSettings?.columns[field.fieldApiName];
        const column: Column<ExtraTableItemType<T>> = {
          accessor: field.fieldApiName as any,
          ...columnProps,
          Header: getHeaderTitle(columnProps, field),
          Cell: getCellRenderer(columnProps, field),
        };
        acc.set(field.fieldApiName, column);
        return acc;
      },
      new Map<string, Column<ExtraTableItemType<T>>>(),
    );
    const columnsFromMetadata = Array.from(columnsFromSalesforceMap.values());

    const beforePersistentColumns = mapPersistentColumns(
      renderSettings?.beforePersistentColumns ?? {},
    );
    const afterPersistentColumns = mapPersistentColumns(
      renderSettings?.afterPersistentColumns ?? {},
    );

    return [
      ...beforePersistentColumns,
      ...columnsFromMetadata,
      ...afterPersistentColumns,
    ];
  }, [renderSettings, getTableFields, tableId]);
  return { columns, loading };
};

function getHeaderTitle<T extends RecordWithExtraFields>(
  columnRender: ColumnRenderSettings<T> | undefined,
  field: ExtraFieldTableInfoFragment,
) {
  // Order of priority
  // 1 flair__Extra_Table_Field__mdt.flair__Column_Title__c
  // 2 Employee hub render settings
  // 3 Salesforce field label
  return (
    field.columnTitle ?? columnRender?.Header ?? field.fieldInfo?.label ?? ''
  );
}

function getCellRenderer<T extends RecordWithExtraFields>(
  columnRender: ColumnRenderSettings<T> | undefined,
  field: ExtraFieldTableInfoFragment,
) {
  if (columnRender?.Cell) {
    return columnRender.Cell;
  }
  return (props: CellProps<ExtraTableItemType<T>>) => {
    return defaultCellRenderer<T>(props, field);
  };
}

// we can implement custom logic for different types
// for example for employee we render avatar
function defaultCellRenderer<T extends RecordWithExtraFields>(
  props: CellProps<ExtraTableItemType<T>>,
  field: ExtraFieldTableInfoFragment,
) {
  if (field.fieldInfo?.referenceObjectApiName?.length) {
    return defaultLookupRenderer<T>(props, field);
  }
  return defaultSimpleRenderer<T>(props, field);
}

function mapPersistentColumns<T extends RecordWithExtraFields>(
  columns: Record<string, ColumnRenderSettings<T>>,
) {
  return Object.entries(columns).map(([columnName, columnProps]) => {
    return {
      ...columnProps,
      accessor: columnName as any,
      Header: columnProps.Header ?? '',
    };
  });
}
