import React from 'react';
import { useObjectExtraFields } from '../useObjectExtraFields';
import { ExtraFieldsAreaContent, Loading } from './ExtraFieldsAreaContent';
import {
  ExtraFieldContextParam,
  ExtraFieldsFormId,
  ExtraFieldsSupportedObject,
} from '../types';
import ExtraFieldsErrorBoundary from '../ExtraFieldsErrorBoundary';
import { ExtraFieldsError } from '../ExtraFieldsError';

type Props = {
  objectApiName: ExtraFieldsSupportedObject;
  formId: ExtraFieldsFormId;
  contextParams?: ExtraFieldContextParam[];
};

export const ExtraFieldsArea: React.FC<Props> = ({
  objectApiName,
  formId,
  contextParams,
}) => {
  const { rawObjectExtraFields, getFormFields, loading, error } =
    useObjectExtraFields(objectApiName);

  if (loading) return <Loading />;
  const serverError = `Server error: ${error?.message}`;
  if (error) return <ExtraFieldsError error={serverError} />;
  if (!rawObjectExtraFields) return null;
  const { availableRecordTypes } = rawObjectExtraFields;
  return (
    <ExtraFieldsErrorBoundary>
      <ExtraFieldsAreaContent
        contextParams={contextParams}
        availableRecordTypes={availableRecordTypes}
        formFields={getFormFields(formId)}
      />
    </ExtraFieldsErrorBoundary>
  );
};
