import { useMemo } from 'react';
import {
  ExtraFieldFormInfoFragment,
  ExtraFieldTableInfoFragment,
  useAllObjectsExtraFieldsQuery,
} from '../../__generated__/graphql';
import {
  ExtraFieldsFormId,
  ExtraFieldsSupportedObject,
  ExtraFieldsTableId,
} from './types';

export const useObjectExtraFields = (
  objectApiName: ExtraFieldsSupportedObject,
) => {
  // we query all object at once because it is cheap operation
  const { data, loading, error } = useAllObjectsExtraFieldsQuery({
    variables: {
      objectApiNames: Object.values(ExtraFieldsSupportedObject),
    },
  });
  const objectExtraFields = useMemo(
    () =>
      data?.extraFields.objectsExtraFields.find(
        (objectExtraFields) =>
          objectExtraFields.objectApiName === objectApiName,
      ),
    [data, objectApiName],
  );

  const getFormFields = (formId: ExtraFieldsFormId) => {
    // take with specific formId first
    const formFieldsMap = objectExtraFields?.form.reduce((acc, cur) => {
      if (cur.formId === null && !acc.has(cur.fieldApiName)) {
        acc.set(cur.fieldApiName, cur);
      }
      if (cur.formId === formId) {
        acc.set(cur.fieldApiName, cur);
      }
      return acc;
    }, new Map<string, ExtraFieldFormInfoFragment>());
    return formFieldsMap ? Array.from(formFieldsMap.values()) : [];
  };

  const getTableFields = (tableId: ExtraFieldsTableId) => {
    const tableFieldsMap = objectExtraFields?.table.reduce((acc, cur) => {
      if (cur.tableId === null && !acc.has(cur.fieldApiName)) {
        acc.set(cur.fieldApiName, cur);
      }
      if (cur.tableId === tableId) {
        acc.set(cur.fieldApiName, cur);
      }
      return acc;
    }, new Map<string, ExtraFieldTableInfoFragment>());
    return tableFieldsMap ? Array.from(tableFieldsMap.values()) : [];
  };
  return {
    rawObjectExtraFields: objectExtraFields,
    getFormFields,
    getTableFields,
    loading,
    error,
  };
};
